import React, {useState} from 'react'

import "../css/currentAssignmentsDetails.css";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {type} from "node:os";

// @ts-ignore
function AssignmentDetailTable(data)
{

    if(data.data.length>0)
    {
        return(<div className="assignment-detail-data-container">
            <table className="assignment-detail">
                {
                   data.data.map((value: string, index: string) => {
                        return (<tr key={uuidv4()}>
                            <td>{value[0]}:</td>
                            <td>{value[1]}</td>
                        </tr>);
                    })
                }
            </table>
        </div>)
    }
    return(<></>)


}

export default AssignmentDetailTable