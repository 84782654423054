import React from 'react'
import '../css/navbar.css'
import '../assests/images/img.png'
import Overlay from "./Overlay";
import {useState} from "react";
import ContactInfo from "./ContactInfo";
import Help from "./Help";
import { setCurrentSupplier } from '../services/auth/LoginModule';
import {insecureMode} from "../utils/utils";

function Navbar()
{
    const[open,setOpen]=useState(false)
    const[content, setContent]=useState(<ContactInfo/>)

    function handleLogoff()
    {
        setCurrentSupplier(null).then(() => {
            if(insecureMode()) {
                window.history.pushState('', '', '/');
                window.location.reload();
            } else {
                window.location.assign(process.env.REACT_APP_GATEWAY_URL + "/auth/logoff");
            }
        })
    }

    const onClickContact = () =>{
        setOpen(!open)
        setContent(<ContactInfo/>)
    }

    const onClickHelp =() =>{
        setOpen(!open)
        setContent(<Help/>)
    }

    return(
        <>
            <div className="navbar">
                <img className="navbar-image" src="img.png" alt={"Provider Web"} />
                <h1 className="navbar-text">Provider Web</h1>
                <ul className="navbar-menu">
                    <li>
                        <button onClick={onClickContact}>Contact Info</button>
                    </li>
                    <li>
                        <button onClick={onClickHelp}>Help</button>
                    </li>
                    <li>
                        <button onClick={handleLogoff}>Logout</button>
                    </li>
                </ul>
            </div>
            <Overlay isOpen={open} onClose={()=>setOpen(!open)} children={content}/>
        </>
    )
}

export default Navbar
