import {insecureMode, WebClient} from "../../utils/utils";
let axios = new WebClient();
const log = require('debug')("LoginModule");

export const isUserAuthenticated = async function() {
  let authenticated = false;
  if (insecureMode()) {
    log("Overriding user login")
    authenticated=true;
  } else {
    let res = await axios.get("/auth/authenticated");
    if(res && res.data && res.data.authenticated !== undefined) {
      authenticated = res.data.authenticated;
    } else {
      return Promise.reject();
    }
  }
  if(!authenticated) {
    log("Redirecting to ",process.env.REACT_APP_GATEWAY_URL + "/auth/login?homePage=" + window.location.href);
    window.location.assign(process.env.REACT_APP_GATEWAY_URL + "/auth/login?homePage=" + window.location.href)
  }
  return Promise.resolve(authenticated);
}

export const updateURL = function(url : string) {
  if(insecureMode() && localStorage.getItem("GSDB")) {
    if(localStorage.getItem("GSDB")) {
      if(url.indexOf("?") > 0) {
        url += "&"
      } else {
        url += "?"
      }
      url += "gsdb=" + localStorage.getItem("GSDB");
    }
  }
  return url;
}

export const isProfileSet = async function () {
  let profileSet = false;
  if (insecureMode()) {
    profileSet = !!localStorage.getItem("GSDB");
  } else {
    let res = await axios.get("/auth/profile");
    if(res && res.data && res.data.profileSet !== undefined) {
      profileSet=res.data.profileSet;
    }
  }
  return profileSet;
}

export const setCurrentSupplier = async function(code : string|null) {
  if (code) {
    localStorage.setItem("GSDB", code);
  } else {
    localStorage.removeItem("GSDB");
  }
  if(insecureMode()) {
    return Promise.resolve();
  } else {
    return axios.put(`/auth/emulate?sitecode=${code}`);
  }
}

export const isUserInternal = async function () {
  if (insecureMode()) {
    return Promise.resolve(true);
  } else {
    try {
      let res = await axios.get("/auth/groups/admin");
      if(res && res.status) {
        return Promise.resolve(res.status === 200);
      } else {
        return Promise.resolve(false);
      }
    } catch(e:any) {
      return Promise.resolve(false);
    }
  }
}