import React from'react'
import '../css/contactinfo.css'

function ContactInfo()
{
    return(
        <div>
            <h2 className="contact-title">Contact Info</h2>
            <div className="contact-info">
                <h3>For Business/How To related issues contact:</h3>

                <ul>
                    <li>Add-on & Contracts Team</li>
                    <li>Email:fcaddon@ford.com</li>
                </ul>
            </div>
            <div className="contact-info">
                <h3 className="contact-info-technical">For Technical related issues contact:</h3>
                <ul>
                    <li>Ford Credit North American Support Center:</li>
                    <li>(888)317-4957</li>
                </ul>
            </div>
        </div>
    )
}

export default ContactInfo
