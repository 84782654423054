import React, {useEffect, useState} from 'react'
import AssignmentDetailTable from "../components/AssignmentDetailTable";
import {useLoaderData, useNavigate} from "react-router-dom";
import "../css/currentAssignmentsDetails.css"
import {currentAssignmentSave} from "../services/api/currentAssignmentApi";
import {CUSTOMER_FIELDS,CUSTOMER_JSON_FIELDS,PRODUCT_FIELDS,PRODUCT_JSON_FIELDS } from "../utils/constants"
import {processObjects, validateNumber} from "../utils/utils"

function CurrentAssignmentDetails() {

    const data  =useLoaderData();
    const [customerData,setCustomerData]=useState([])
    const [productData,setProductData]=useState([])
    const [refundData,setRefundData]=useState({})
    const [errorData,setErrorData]=useState([])

    const navigate =useNavigate()

    function handleCancel()
    {
        navigate("/")

    }

    function buildCustomerData(assignmentDetail)
    {
        let assignmentDetailCustomerData
        if(assignmentDetail!=null)
        {
            assignmentDetailCustomerData= processObjects(CUSTOMER_FIELDS,CUSTOMER_JSON_FIELDS,assignmentDetail)
            setCustomerData(customerData=>[...assignmentDetailCustomerData])
        }
    }

    function buildProductData(assignmentDetail)
    {
        let assignmentDetailProductData
        if(assignmentDetail!=null) {
            assignmentDetailProductData= processObjects(PRODUCT_FIELDS,PRODUCT_JSON_FIELDS,assignmentDetail)
            setProductData(productData=>[...assignmentDetailProductData])
        }
    }

    function buildRefundData(assignmentDetail)
    {
        console.log(assignmentDetail)
        const refund={}
        const reductions ={}
        if(assignmentDetail!=null) {
            refund.customerCost=assignmentDetail.customerCost.toFixed(2)
            refund.teamworkBpiNb=assignmentDetail.assignment.teamworkBpiNb
            refund.customerAccount=assignmentDetail.assignment.customerAccount
            refund.vin=assignmentDetail.assignment.vin
            refund.coBusCd=assignmentDetail.assignment.coBusCd
            refund.status=assignmentDetail.assignment.status
            refund.superBranchCd=assignmentDetail.superBranchCd
            //refund.refundDue=assignmentDetail.rebateAmount > 0 ? assignmentDetail.rebateAmount :"120"
            console.log(assignmentDetail.rebateAmount)
            refund.refundDue=assignmentDetail.rebateAmount
            refund.reason=assignmentDetail.noRefundReason
            refund.additionalInfo=assignmentDetail.requiresAdditionalInfo
            refund.notInDatabase=assignmentDetail.notInDatabase
            console.log(assignmentDetail.deductions.length)
            assignmentDetail.deductions.map((value,index)=>
            {
                if(value.type==="Cancellation Fee")
                {
                    console.log("found cancellation");
                    reductions.cancellation=[value.amount,value.description]
                }
                else if(value.type==="ProRated Term/Miles")
                {
                    console.log("found ProRated");
                    reductions.prorated=[value.amount,value.description]
                }
                else if(value.type==="Paid Claims")
                {
                    console.log("found Paid");
                    reductions.paid=[value.amount,value.description]
                }
                else if(value.type==="Other")
                {
                    reductions.other=[value.amount,value.description]
                }
            })

            refund.deductions=reductions
            console.log(refund)
            setRefundData(refund)
            console.log(refundData)
        }
    }



    useEffect(()=>{

        console.log(data)
        buildCustomerData(data)
        buildProductData(data)
        buildRefundData(data)

    },[])
    return (
        <div className="assignmentDetail-container">
            <h3 className="topMessage">***PROVIDE REFUND QUOTE BASED ON FORD CREDIT'S CUSTOMER COST (NOT DEALER COST)***</h3>
            <h2 style={{marginTop:"10px"}}>Assignment Detail</h2>
            <AssignmentDetailTable data={customerData}/>
            <h3 style={{marginTop:"15px"}}>Product Details for Refund Quote</h3>
            <hr style={{marginTop:"-5px"}}/>
            <AssignmentDetailTable data={productData}/>
            <h3 style={{marginTop:"15px"}}>Refund Quote</h3>
            <hr style={{marginTop: "-5px"}}/>
            <Error data={errorData}/>
            <RefundQuote data={refundData} setErrorData={setErrorData} errorData={errorData}/>
        </div>
    )
}

export function RefundQuote(data) {

    console.log(data.data.refundDue)
    const [refundData, setRefundData] = useState({})

    const[refundDueAmountZero,setRefundDueAmountZero]=useState(false)

    const[proRateDeductionZero,setProRateDeductionZero]=useState(false)
    const[paidClaimsDeductionZero,setPaidClaimsDeductionZero]=useState(false)
    const[cancellationFeeDeductionAmountZero,setCancellationFeeDeductionAmountZero]=useState(false)
    const[otherDeductionZero,setOtherDeductionZero]=useState(false)

    const navigate = useNavigate()




    function handleCancel() {
        navigate("/")

    }

    function processDeductions(refundData)
    {
        let deductions =[]
        let deductionCategories=["prorated","paid","cancellation","other"]
        let deductionCategoriesMap=new Map([
            ["prorated","ProRated Term/Miles"],
            ["paid","Paid Claims"],
            ["cancellation","Cancellation Fee"],
            ["other","Other"],
        ])
        console.log(refundData)
        for(let i=0;i<deductionCategories.length;i++)
        {
            if(refundData["deductions"][deductionCategories[i]][0]>0)
            {
                deductions.push({"type":deductionCategoriesMap.get(deductionCategories[i]),"amount":parseInt(refundData["deductions"][deductionCategories[i]][0]),"description":refundData["deductions"][deductionCategories[i]][1]})
            }

        }
        return deductions
    }

    function processPayloadBeforeSubmit(refundData)
    {
        console.log("refundData.refundDue---->:"+refundData.refundDue)
        refundData={...refundData,"refundDue":parseFloat(refundData.refundDue)}



        let currentAssignmentSavePayload={
            "assignment":{
                "teamworkBpiNb":refundData.teamworkBpiNb,
                "customerAccount":refundData.customerAccount,
                "vin":refundData.vin,
                "coBusCd":refundData.coBusCd,
                "status":refundData.status
            }
        }

        if(refundData.deductions!=null)
        {
            currentAssignmentSavePayload["deductions"]=refundData.deductions
        }


        if(refundData.additionalInfo!=null && refundData.additionalInfo)
        {
            currentAssignmentSavePayload["requiresAdditionalInfo"]=refundData.additionalInfo

        } else if(refundData.notInDatabase!=null && refundData.notInDatabase)
        {
            currentAssignmentSavePayload["notInDatabase"]=refundData.notInDatabase

        }
        if(refundData.refundDue!=null && !isNaN(refundData.refundDue))
        {
            currentAssignmentSavePayload["rebateAmount"]=refundData.refundDue

        }

        if(refundData.reason!=null && refundData.reason!=="-----------------------None----------------------------")
        {
            currentAssignmentSavePayload["noRefundReason"]=refundData.reason

        }

        currentAssignmentSavePayload["superBranchCd"]=refundData.superBranchCd

        console.log(currentAssignmentSavePayload)
        return currentAssignmentSavePayload
    }

    function handleSubmit()
    {
        console.log("inside submit*********************************")
        console.log(refundData)

        let refundDataSave={...refundData}

        console.log(refundData)
        processDeductions(refundData)

        let deductions =processDeductions(refundData)

        console.log("deductions.length--->" + deductions.length)
        console.log(refundDataSave)
        if(deductions.length>0)
        {
            refundDataSave["deductions"]=deductions
        }
        else
        {
            delete refundDataSave.deductions
        }
        console.log(refundDataSave)

        refundDataSave=processPayloadBeforeSubmit(refundDataSave)
        console.log(refundDataSave)
        currentAssignmentSave({...refundDataSave}).catch(err=>{
            navigate("/error")
        }).then(res=>{
                navigate("/")
            }
        )

    }

    function handleRefundDueInUseEffect()
    {
        if(refundData.refundDue==0 && !refundDueAmountZero)
        {
            console.log("refundData.refundDue==0 && !refundDueAmountZero")

            // console.log("#################")
            setRefundData((prev)=>{
                //  console.log(prev.additionalInfo)
                return {...prev,"refundDue":""}})
        }
        else
        {

            //This saved my life
            //   console.log("###@@@@@@@@@@@@@##############")
            console.log("else")

            setRefundData((prev)=>{
                // console.log(prev)
                return {...prev,"refundDue":refundData.refundDue}}
            )
        }

    }

    function handleProrateDeductionInUseEffect()
    {
        if(refundData.deductions.prorated[0]==0 && !proRateDeductionZero)
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "prorated":["",prev.deductions.prorated[1]]

                }
                return {...prev,deductions:deductions}} )
        }
        else
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "prorated":[refundData.deductions.prorated[0],refundData.deductions.prorated[1]],
                }
                return {...prev,deductions:deductions}}
            )
        }
    }

    function handlePaidDeductionInUseEffect()
    {
        if(refundData.deductions.paid[0]==0 && !paidClaimsDeductionZero)
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "paid":["",prev.deductions.paid[1]]

                }
                return {...prev,deductions:deductions}} )
        }
        else
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "paid":[refundData.deductions.paid[0],refundData.deductions.paid[1]],
                }
                return {...prev,deductions:deductions}}
            )
        }
    }

    function handleCancellationDeductionInUseEffect()
    {

        if(refundData.deductions.cancellation[0]==0 && !cancellationFeeDeductionAmountZero)
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "cancellation":["",prev.deductions.cancellation[1]]

                }
                return {...prev,deductions:deductions}} )
        }
        else
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "cancellation":[refundData.deductions.cancellation[0],refundData.deductions.cancellation[1]],
                }
                return {...prev,deductions:deductions}}
            )
        }
    }

    function handleOtherDeductionInUseEffect()
    {
        if(refundData.deductions.other[0]===0 && !otherDeductionZero)
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "other":["",prev.deductions.other[1]]

                }
                return {...prev,deductions:deductions}} )
        }
        else
        {
            setRefundData((prev)=>{
                let deductions={
                    ...prev.deductions,
                    "other":[refundData.deductions.other[0],refundData.deductions.other[1]]

                }
                return {...prev,deductions:deductions}}
            )
        }

    }

    function handleSubmitDisableInUseEffectForReasonNotSelected()
    {
        if (!document.getElementById("reason").disabled && document.getElementById("reason").selectedIndex == 0) {
            console.log("Firing 1")
            document.getElementById("submit").disabled = true
            if (document.getElementById("submit").classList.contains("current-assignment-submit")) {
                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }
        }
    }

    function handleSubmitInUseEffectForRefundDueAdditionalInfoNotInDatabase()
    {
        if ((document.getElementById("refundDue").value == "" || document.getElementById("refundDue").value == "0") &&
            (!document.getElementById("additionalInfo").checked && !document.getElementById("notInDatabase").checked) &&
            refundData.refundDue == null) {
            console.log("Firing 2")
            document.getElementById("submit").disabled = true
            if (document.getElementById("submit").classList.contains("current-assignment-submit")) {
                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }
        } else {
            document.getElementById("submit").disabled = false
            if (document.getElementById("submit").classList.contains("current-assignment-submit-error")) {
                document.getElementById("submit").classList.remove("current-assignment-submit-error")
                document.getElementById("submit").classList.add("current-assignment-submit")
            }

        }
    }

    function handleSubmitInUseEffectForReasonNone()
    {
        if (document.getElementById("reason").value == "-----------------------None----------------------------" && !document.getElementById("reason").disabled) {
            document.getElementById("submit").disabled = true
            if (document.getElementById("submit").classList.contains("current-assignment-submit")) {
                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }
        }
    }

    function handleAdditonalInfoInUseEffect()
    {
        if(refundData.additionalInfo!=null)
        {
            console.log("inside refundData.additionalInfo!=null")

            setRefundData((prev)=>{
                return {...prev,"additionalInfo":refundData.additionalInfo}})

        }
    }

    function handleNotInDatabaseInUseEffect()
    {
        if(refundData.notInDatabase!=null)
        {
            // console.log("$$$$$$$$$$$#####jolly")
            console.log("refundData.notInDatabase")

            setRefundData((prev)=>{
                return {...prev,"notInDatabase":refundData.notInDatabase}})

        }
    }

    function handleSubmitInUseEffectWhenErrors()
    {
        console.log("Firing 3")
        document.getElementById("submit").disabled=true
        if(document.getElementById("submit").classList.contains("current-assignment-submit"))
        {
            document.getElementById("submit").classList.remove("current-assignment-submit")
            document.getElementById("submit").classList.add("current-assignment-submit-error")
        }

    }

    function handleSubmitInUseEffectWhenNoErrors()
    {
        document.getElementById("submit").disabled = false
        if (document.getElementById("submit").classList.contains("current-assignment-submit-error")) {
            document.getElementById("submit").classList.remove("current-assignment-submit-error")
            document.getElementById("submit").classList.add("current-assignment-submit")
        }
    }

    useEffect(()=>{
        console.log("USE EFFECT **************STARTS*********")
        console.log(refundData)


        if(Object.keys(data.data).length>0)
        {
            console.log(data.data)
            console.log(refundData)
            setRefundData({})
            console.log("USE EFFECT **************inside if block********")
            console.log(data.data.refundDue)
            setRefundData((prev)=>{return{...prev,...data.data}})
            console.log(refundData)
        }

        handleAdditonalInfoInUseEffect()

        handleNotInDatabaseInUseEffect()

        handleRefundDueInUseEffect()

        console.log(refundData)

        if(refundData.deductions!=null)
        {
            handleProrateDeductionInUseEffect()

            handlePaidDeductionInUseEffect()

            handleCancellationDeductionInUseEffect()

            handleOtherDeductionInUseEffect()

        }


        if(data.errorData.length==0 && (!document.getElementById("refundDue").value=="" || !document.getElementById("refundDue").value==null)) {

            handleSubmitInUseEffectWhenNoErrors()

            handleSubmitDisableInUseEffectForReasonNotSelected()

            handleSubmitInUseEffectForRefundDueAdditionalInfoNotInDatabase()

            //Fix for Submit disable when no reason selected
            handleSubmitInUseEffectForReasonNone()

        }
        else
        {
            handleSubmitInUseEffectWhenErrors()
        }

        console.log(refundData)
        console.log("USE EFFECT *************ENDS*********:")
    },[data.data,data.errorData])


    function setDeductionsState(deductionCategory,deductionValues)
    {
        setRefundData((prev)=>{
            let deductions={
                ...prev.deductions,
                deductionCategory:deductionValues
            }
            return {...prev,deductions:deductions}}
        )

    }


    function determinSubmitEnableByRefundDueAndReason(selectedIndex)
    {
        if ((document.getElementById("refundDue").value==0 &
                document.getElementById("reason").disabled &
                document.getElementById("reason").value == "-----------------------None----------------------------")
            ||
            (parseFloat(document.getElementById("refundDue").value) ==0 && selectedIndex =="0")
        ) {
            console.log("Why you are executing ---?" + document.getElementById("reason").disabled)
            document.getElementById("submit").disabled = true
            if (document.getElementById("submit").classList.contains("current-assignment-submit")) {
                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }
        } else {
            console.log("This should not happen -")
            document.getElementById("submit").disabled = false
            if (document.getElementById("submit").classList.contains("current-assignment-submit-error")) {
                document.getElementById("submit").classList.remove("current-assignment-submit-error")
                document.getElementById("submit").classList.add("current-assignment-submit")
            }
        }
    }

    function setDeductionsWhenRefundDueIsZero()
    {
        setRefundData((prev) => {
            let deductions = {
                ...prev.deductions,
                "prorated": ["", ""],
                "paid": ["", ""],
                "cancellation": ["", ""],
                "other": ["", ""]

            }
            return {...prev, deductions: deductions}
        })

        if (data.errorData.includes("Other Description: required for ProRated Term/Miles.")) {
            data.setErrorData(s => s.filter(item => item != "Other Description: required for ProRated Term/Miles."))
        }

        if (data.errorData.includes("Other Description: required for Other.")) {
            data.setErrorData(s => s.filter(item => item != "Other Description: required for Other."))

        }
    }

    function toggleSubmitByAdditonalInfoNotInDatabase(checked)
    {
        if(checked)
        {
            document.getElementById("submit").disabled=false
            if(document.getElementById("submit").classList.contains("current-assignment-submit-error"))
            {
                document.getElementById("submit").classList.remove("current-assignment-submit-error")
                document.getElementById("submit").classList.add("current-assignment-submit")
            }

        }
        else
        {
            document.getElementById("submit").disabled=true
            if(document.getElementById("submit").classList.contains("current-assignment-submit"))
            {
                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }

        }
    }

    function handlingDeductionsWhenRefundDueIsZero(value,id)
    {
        if(parseFloat(value)==0 && id==="refundDue")
        {
            console.log("setting true")
            setRefundDueAmountZero(true)

            if (document.getElementById("refundDue").value == 0) {

                setDeductionsWhenRefundDueIsZero()
            }

        }
        else
        {
            setRefundDueAmountZero(false)
        }
    }

    function handleOnChangeData(e)
    {
        setRefundData((prev)=>{
            let helper= {...prev}
            let target_id=e.target.id

            //setting the deductions amount
            if(target_id ==="prorated" || target_id ==="paid" || target_id ==="cancellation" || target_id ==="other")
            {
                helper['deductions'][target_id][0]=e.target.value
            }
            //setting the deductions text
            if(target_id.includes("-text"))
            {
                let deductionText=e.target.value
                deductionText=deductionText.trim().length===0?deductionText.trim():deductionText
                console.log(e.target.value.trim().length)
                helper['deductions'][target_id.substring(0,target_id.indexOf("-"))][1]=deductionText
            }

            if(target_id==="additionalInfo" || target_id==="notInDatabase")
            {
                helper[`${target_id}`]=e.target.checked

                toggleSubmitByAdditonalInfoNotInDatabase(e.target.checked)
            }
            else
            {
                helper[`${target_id}`]=e.target.value
            }
            console.log(helper)

            if(target_id==="reason")
            {
                helper["reason"]=e.target.value
            }
            return helper
        })

        handlingDeductionsWhenRefundDueIsZero(e.target.value,e.target.id)

        determinSubmitEnableByRefundDueAndReason(e.target.selectedIndex)

        handleDeductionAmount(e.target.value,e.target.id)

        // console.log(data.errorData)

    }

    function handleDeductionAmount(value,deductionCategory)
    {

        switch (deductionCategory)
        {
            case "prorated":
                if(parseFloat(value)==0)
                {
                    console.log("setting true")
                    setProRateDeductionZero(true)
                }
                else
                {
                    setProRateDeductionZero(false)
                }
                break;

            case "paid":
                if(parseFloat(value)==0)
                {
                    console.log("setting true")
                    setPaidClaimsDeductionZero(true)
                }
                else
                {
                    setPaidClaimsDeductionZero(false)
                }
                break;

            case "cancellation":
                if(parseFloat(value)==0)
                {
                    console.log("setting true")
                    setCancellationFeeDeductionAmountZero(true)
                }
                else
                {
                    setCancellationFeeDeductionAmountZero(false)
                }
                break;


            case "other":
                if(parseFloat(value)==0)
                {
                    console.log("setting true")
                    setOtherDeductionZero(true)
                }
                else
                {
                    setOtherDeductionZero(false)
                }
                break;

            default:
                break;

        }

    }


    function determineSubmitEnableByRefundDue(value,refundDueInvalid)
    {
        if(document.getElementById("submit").disabled && value > 0 && !refundDueInvalid)
        {
            document.getElementById("submit").disabled=false
            if(document.getElementById("submit").classList.contains("current-assignment-submit-error"))
            {
                document.getElementById("submit").classList.remove("current-assignment-submit-error")
                document.getElementById("submit").classList.add("current-assignment-submit")
            }
        }
        else if(value=="" || value ==null || refundDueInvalid)
        {
            document.getElementById("submit").disabled=true
            if(document.getElementById("submit").classList.contains("current-assignment-submit"))
            {

                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }
        }
    }

    function checkIfRefundDueLessThanCustomerCost(value)
    {
        let refundDueInvalid
        if (value > parseFloat(refundData.customerCost) && !data.errorData.includes("Customer Refund Due: Enter amount less than or equal to Customer Cost."))
        {
            data.setErrorData(s=>[...s,"Customer Refund Due: Enter amount less than or equal to Customer Cost."])
            refundDueInvalid=true
        }
        else if((value!=null && value!="") && (value <= parseFloat(refundData.customerCost)))
        {
            data.setErrorData(s=>s.filter(item=>item!="Customer Refund Due: Enter amount less than or equal to Customer Cost."))
        }
        return refundDueInvalid
    }

    function handleRefundDueAmount(e)
    {
        console.log("submit status starting -------->:"+ document.getElementById("submit").disabled)
        console.log("inside handleValidate:"+ document.getElementById(e.target.id).value)

        let refundDueInvalid=false
        let value=getRefundDueAmount(e.target.value)

        console.log("getting refund values--->" + value)

        setRefundData((refundData)=>{return {...refundData,"refundDue":value}})

        console.log(refundData)
        console.log("999999999999999999999--->"+e.target.value)
        console.log("------------->---->--->-->"+e.target.value)

        if(parseInt(e.target.value)===0)
        {
            handleDisableDeductions();
        }
        else
        {
            handleEnableDeductions();
        }

        if(parseFloat(e.target.value)==0)
        {
            document.getElementById("reason").disabled=false
        }
        else if(parseFloat(e.target.value)>0 || e.target.value==="")
        {
            document.getElementById("reason").disabled=true
            setRefundData({...refundData,"reason":"-----------------------None----------------------------" })
        }

        if(!validateNumber(e.target.value))
        {
            if(!data.errorData.includes("Customer Refund Due: Invalid Format."))
            {
                data.setErrorData(s=>[...s,"Customer Refund Due: Invalid Format."])
                refundDueInvalid=true
            }
            document.getElementById("reason").disabled=true
        }
        else
        {
            data.setErrorData(s=>s.filter(item=>item!="Customer Refund Due: Invalid Format."))
        }


        if(e.target.value<0)
        {
            if(!data.errorData.includes("Customer Refund Due: Amount less than Zero."))
            {
                data.setErrorData(s=>[...s,"Customer Refund Due: Amount less than Zero."])
                refundDueInvalid=true
            }
            document.getElementById("reason").disabled=true
        }
        else
        {
            data.setErrorData(s=>s.filter(item=>item!="Customer Refund Due: Amount less than Zero."))
        }

        refundDueInvalid=checkIfRefundDueLessThanCustomerCost(e.target.value)

        determineSubmitEnableByRefundDue(e.target.value,refundDueInvalid)

        console.log("submit status ending-------->:"+ document.getElementById("submit").disabled)
        console.log(refundData)
        console.log("Ending the validation**************************")
    }


    function handleProratedDeductionDescriptionErrorMessage(id,value)
    {
        if(id==="prorated" && value>0 && document.getElementById("prorated-text").value.length===0 && !data.errorData.includes("Other Description: required for ProRated Term/Miles."))
        {
            data.setErrorData(s=>{return [...s,"Other Description: required for ProRated Term/Miles."]})
        }
        else if(id==="prorated" && (value==0 || value==="") && data.errorData.includes("Other Description: required for ProRated Term/Miles."))
        {
            data.setErrorData(s=>s.filter(item=>item!="Other Description: required for ProRated Term/Miles."))

        }
    }

    function handleOtherDeductionDescriptionErrorMessage(id,value)
    {
        if(id==="other" && value>0 && document.getElementById("other-text").value.length==0 && !data.errorData.includes("Other Description: required for Other."))
        {
            data.setErrorData(s=>{return [...s,"Other Description: required for Other."]})
        }
        else if(id==="other" && (value==0 || value==="") && data.errorData.includes("Other Description: required for Other."))
        {
            data.setErrorData(s=>s.filter(item=>item!="Other Description: required for Other."))

        }
    }

    function setDeductionsWhenDeductionAmountZero(value,deductionCategoryKey,deductionCategoryText)
    {
        if(value=="" && document.getElementById(deductionCategoryText).value.length>0)
        {
            setRefundData((prev) => {
                let deductions = {
                    ...prev.deductions,
                }
                deductions[deductionCategoryKey]=["",""]
                return {...prev, deductions: deductions}
            })
        }
    }

    function handleDeductionAmountInvalidMessage(errorMessage)
    {
        if(!data.errorData.includes(errorMessage))
        {
            data.setErrorData(s=>{return [...s,errorMessage]})
        }
    }

    function handleValidateDeductionAmount(e)
    {
        if(!validateNumber(e.target.value))
        {
            switch (e.target.id)
            {
                case "prorated":
                    handleDeductionAmountInvalidMessage("Deduction - ProRated Term/Miles: Invalid Number")
                    break
                case "paid":
                    handleDeductionAmountInvalidMessage("Deduction - Paid Claims: Invalid Number")
                    break
                case "cancellation":
                    handleDeductionAmountInvalidMessage("Deduction - Cancellation Fee: Invalid Number")
                    break
                case "other":
                    handleDeductionAmountInvalidMessage("Deduction - Other: Invalid Number")
                    break
                default:
                    break
            }
        }
        else
        {

            handleProratedDeductionDescriptionErrorMessage(e.target.id,e.target.value)

            handleOtherDeductionDescriptionErrorMessage(e.target.id,e.target.value)

            console.log(document.getElementById("paid-text").value.length)
            console.log(e.target.value=="")
            switch (e.target.id)
            {
                case "prorated":
                    setDeductionsWhenDeductionAmountZero(e.target.value,"prorated","prorated-text")
                    data.setErrorData(s=>s.filter(item=>item!="Deduction - ProRated Term/Miles: Invalid Number"))
                    break
                case "paid":
                    setDeductionsWhenDeductionAmountZero(e.target.value,"paid","paid-text")
                    data.setErrorData(s=>s.filter(item=>item!="Deduction - Paid Claims: Invalid Number"))
                    break
                case "cancellation":
                    setDeductionsWhenDeductionAmountZero(e.target.value,"cancellation","cancellation-text")
                    data.setErrorData(s=>s.filter(item=>item!="Deduction - Cancellation Fee: Invalid Number"))
                    break
                case "other":
                    setDeductionsWhenDeductionAmountZero(e.target.value,"other","other-text")
                    data.setErrorData(s=>s.filter(item=>item!="Deduction - Other: Invalid Number"))
                    break
                default:
                    break
            }
        }
    }

    function handleReasonForZeroAmount(e)
    {

        console.log("handling reasonfor lsdfknlsdkfndslflkdnflkmfksdlklsdn")
        if(parseFloat(document.getElementById("refundDue").value) ==0 && e.target.selectedIndex !="0")
        {

            document.getElementById("submit").disabled=false
            if(document.getElementById("submit").classList.contains("current-assignment-submit-error"))
            {
                document.getElementById("submit").classList.remove("current-assignment-submit-error")
                document.getElementById("submit").classList.add("current-assignment-submit")
            }

        }
        else
        {
            document.getElementById("submit").disabled=true
            if(document.getElementById("submit").classList.contains("current-assignment-submit"))
            {
                document.getElementById("submit").classList.remove("current-assignment-submit")
                document.getElementById("submit").classList.add("current-assignment-submit-error")
            }
        }

        setRefundData((prev)=> {
            let helper= {...prev}
            helper["reason"]=e.target.value
            return helper
        })

        console.log(refundData)
        console.log(e.target.value)
        console.log(refundData)
    }


    function handleDisableDeductions()
    {
        document.getElementById("prorated").disabled=true
        document.getElementById("prorated-text").disabled=true
        document.getElementById("prorated").value=""
        document.getElementById("prorated-text").value=""

        document.getElementById("paid").disabled=true
        document.getElementById("paid-text").disabled=true
        document.getElementById("paid").value=""
        document.getElementById("paid-text").value=""

        document.getElementById("cancellation").disabled=true
        document.getElementById("cancellation-text").disabled=true
        document.getElementById("cancellation").value=""
        document.getElementById("cancellation-text").value=""

        document.getElementById("other").disabled=true
        document.getElementById("other-text").disabled=true
        document.getElementById("other").value=""
        document.getElementById("other-text").value=""


        setRefundData((prev) => {
            let deductions = {
                ...prev.deductions,
                "prorated": ["", ""],
                "paid": ["", ""],
                "cancellation": ["", ""],
                "other": ["", ""]

            }
            return {...prev, deductions: deductions}
        })

        if (data.errorData.includes("Other Description: required for ProRated Term/Miles.")) {
            data.setErrorData(s => s.filter(item => item != "Other Description: required for ProRated Term/Miles."))
        }

        if (data.errorData.includes("Other Description: required for Other.")) {
            data.setErrorData(s => s.filter(item => item != "Other Description: required for Other."))

        }

    }

    function handleEnableDeductions()
    {
        document.getElementById("prorated").disabled=false
        document.getElementById("prorated-text").disabled=false

        document.getElementById("paid").disabled=false
        document.getElementById("paid-text").disabled=false

        document.getElementById("cancellation").disabled=false
        document.getElementById("cancellation-text").disabled=false

        document.getElementById("other").disabled=false
        document.getElementById("other-text").disabled=false
    }



    function handleNotInDatabase(e)
    {
        console.log("Hey joly joly joly")
        if(e.target.checked)
        {
            setRefundData({...refundData,"reason":"-----------------------None----------------------------","notInDatabase":true,"refundDue":""})
            document.getElementById("refundDue").value=0
            document.getElementById("refundDue").disabled=true
            document.getElementById("reason").selectedIndex=0
            document.getElementById("reason").disabled=true
            document.getElementById("additionalInfo").disabled=true

            if(data.errorData.includes("Customer Refund Due: Invalid Format."))
            {
                data.setErrorData(s=>s.filter(item =>item!="Customer Refund Due: Invalid Format."))
            }else if(data.errorData.includes("Customer Refund Due: Enter amount less than or equal to Customer Cost."))
            {
                data.setErrorData(s=>s.filter(item =>item!="Customer Refund Due: Enter amount less than or equal to Customer Cost."))
            }
            handleDisableDeductions()
        }
        else
        {
            document.getElementById("refundDue").disabled=false
            document.getElementById("additionalInfo").disabled=false
            handleEnableDeductions()
        }
    }

    function handleAdditionalInfoRequired(e)
    {
        if(e.target.checked)
        {
            setRefundData({...refundData,"reason":"-----------------------None----------------------------","additionalInfo":true,"refundDue":""})
            document.getElementById("refundDue").value=0
            document.getElementById("refundDue").disabled=true
            document.getElementById("reason").selectedIndex=0
            document.getElementById("reason").disabled=true
            document.getElementById("notInDatabase").disabled=true

            if(data.errorData.includes("Customer Refund Due: Invalid Format."))
            {
                data.setErrorData(s=>s.filter(item =>item!="Customer Refund Due: Invalid Format."))
            }else if(data.errorData.includes("Customer Refund Due: Enter amount less than or equal to Customer Cost."))
            {
                data.setErrorData(s=>s.filter(item =>item!="Customer Refund Due: Enter amount less than or equal to Customer Cost."))
            }

            handleDisableDeductions()

        }
        else
        {
            document.getElementById("refundDue").disabled=false
            document.getElementById("notInDatabase").disabled=false

            handleEnableDeductions();

        }

    }



    function handleProRatedOrOtherTextComplete(e)
    {
        if(e.target.value.length>0)
        {
            if(e.target.id==="prorated-text")
            {
                data.setErrorData(s=>s.filter(item=>item!="Other Description: required for ProRated Term/Miles."))
            }
            else if(e.target.id==="other-text")
            {
                data.setErrorData(s=>s.filter(item=>item!="Other Description: required for Other."))
            }
        }
        else
        {
            if(e.target.id==="prorated-text" && !data.errorData.includes("Other Description: required for ProRated Term/Miles.")  && document.getElementById("prorated").value>0)
            {
                data.setErrorData(s=>{return [...s,"Other Description: required for ProRated Term/Miles."]})
            }
            else if(e.target.id==="other-text" && !data.errorData.includes("Other Description: required for Other.") && document.getElementById("other").value>0)
            {
                data.setErrorData(s=>{return [...s,"Other Description: required for Other."]})
            }
        }
    }

    function getRefundDueAmount(val)
    {
        console.log("inside getrefunddueamount function")
        let refundDueAmount=""

        if(refundData.refundDue!=0 && !refundDueAmountZero)
        {
            refundDueAmount=refundData.refundDue
        }
        else if(refundData.refundDue==0 && refundDueAmountZero)
        {
            refundDueAmount=0
        }
        else if(refundData.refundDue!=0 && refundDueAmountZero)
        {
            refundDueAmount=val
        }
        console.log("this is what set to refund:"+refundDueAmount)
        return refundDueAmount
    }

    // @ts-ignore
    // @ts-ignore
    return(<div>
        <div className="refund-quote-column refund-column">
            <table className="assignment-refund-quote">
                <tr>
                    <td className="left-column">Customer Cost:</td>
                    <td>${refundData.customerCost}</td>
                </tr>
                <tr>
                    <td>Deductions (as applicable)</td>
                    <td>
                        <table className="deduction">
                            <thead>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Other Description</th>
                            </thead>
                            {
                                refundData.deductions != null && (
                                    <>
                                        <tr>
                                            <td>ProRated Term/Miles</td>
                                            <td><input id="prorated" onChange={e => {
                                                handleOnChangeData(e);
                                            }}
                                                       onKeyUp={e => {
                                                           handleValidateDeductionAmount(e)
                                                       }}
                                                       className="deductions"
                                                       type="text" maxLength="14" value={refundData.deductions.prorated[0]}/></td>
                                            <td><input id="prorated-text" onChange={handleOnChangeData} onBlur={e => {
                                                handleProRatedOrOtherTextComplete(e)
                                            }}
                                                       className="deductions"
                                                       type="text" value={refundData.deductions.prorated[1]} maxLength="60"/></td>
                                        </tr>
                                        <tr>
                                            <td>Paid Claims</td>
                                            <td><input id="paid" onChange={e => {
                                                handleOnChangeData(e);
                                            }}
                                                       onKeyUp={e => {
                                                           handleValidateDeductionAmount(e)
                                                       }}
                                                       className="deductions"
                                                       type="text" maxLength="14" value={refundData.deductions.paid[0]}/></td>
                                            <td><input id="paid-text" onChange={handleOnChangeData} className="deductions"
                                                       type="text" value={refundData.deductions.paid[1]} maxLength="60"/></td>
                                        </tr>
                                        <tr>
                                            <td>Cancellation Fee</td>
                                            <td><input id="cancellation" onChange={e => {
                                                handleOnChangeData(e);
                                            }}
                                                       onKeyUp={e => {
                                                           handleValidateDeductionAmount(e)
                                                       }}
                                                       className="deductions"
                                                       type="text" maxLength="14" value={refundData.deductions.cancellation[0]}/></td>
                                            <td><input id="cancellation-text" onChange={handleOnChangeData}
                                                       className="deductions" type="text"
                                                       value={refundData.deductions.cancellation[1]} maxLength="60"/></td>
                                        </tr>
                                        <tr>
                                            <td>Other</td>
                                            <td><input id="other" onChange={e => {
                                                handleOnChangeData(e);
                                            }}
                                                       onKeyUp={e => {
                                                           handleValidateDeductionAmount(e)
                                                       }}
                                                       className="deductions"
                                                       type="text" maxLength="14" value={refundData.deductions.other[0]}/></td>
                                            <td><input id="other-text" onChange={handleOnChangeData} onBlur={e => {
                                                handleProRatedOrOtherTextComplete(e)
                                            }} className="deductions"
                                                       type="text" value={refundData.deductions.other[1]} maxLength="60"/></td>
                                        </tr>
                                    </>
                                )
                            }

                        </table>
                    </td>
                </tr>
                <tr>
                    <td>Customer Refund Due:</td>
                    <td>$<input id="refundDue" type="text" onChange={handleOnChangeData} onKeyUp={e => {
                        handleRefundDueAmount(e)
                    }}
                                value={refundData.refundDue}/> <span id="refund-text">(Represents net amount of refund due to Ford Credit
                        customer)</span>
                    </td>
                </tr>
                <tr>
                    <td>Reason for $0 Amount:</td>
                    <td>
                        <select id="reason" onChange={handleOnChangeData} value={refundData.reason} disabled={true}>
                            <option
                                value="-----------------------None----------------------------">-----------------------None----------------------------
                            </option>
                            <option value="Customer Cost fully earned">Customer Cost fully earned</option>
                            <option value="Claim has been paid and Customer Cost has been fully earned">Claim has been paid and
                                Customer Cost has been fully earned
                            </option>
                            <option value="Contract/Policy is non-cancellable">Contract/Policy is non-cancellable
                            </option>
                            <option value="Claim pended">Claim pended</option>
                            <option value="Refund amount below state threshold">Refund amount below state threshold
                            </option>
                            <option value="Previously Cancelled">Previously Cancelled</option>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td>Not in Database?:</td>
                    <td><input type="checkbox" name="notInDatabase" id="notInDatabase" disabled={refundData.status==="Pended"} onChange={e => {
                        handleOnChangeData(e);
                        handleNotInDatabase(e)
                    }} checked={refundData.notInDatabase}/></td>
                </tr>

                <tr>
                    <td>Additional Info Reqd?:</td>
                    <td>
                        <td><input id="additionalInfo" type="checkbox" disabled={refundData.status==="Pended"} onChange={e => {
                            handleOnChangeData(e);
                            handleAdditionalInfoRequired(e)
                        }} checked={refundData.additionalInfo}/></td>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button id="submit" className="current-assignment-submit" onClick={handleSubmit}>Submit</button>
                    </td>
                    <td>
                        <button className="current-assignment-cancel" onClick={handleCancel}>Back</button>
                    </td>
                </tr>

            </table>

        </div>

    </div>)
}

function Error(data) {
    const [errors, setErrors] = useState([])
    useEffect(() => {
        setErrors(s => [...data.data])
        console.log(data.data)
        console.log("errors.length:"+errors.length)
        if(data.data.length>0)
        {
            // console.log("asdsadsadsadasdasdasdsadasdasdasddasdas")
            document.getElementById("error-title").style.display="inline"
            document.getElementById("error-column").style.animation="errorblink 0.5s"

        }
        else
        {
            document.getElementById("error-title").style.display="none"
            document.getElementById("error-column").style.animation=""
        }
    },[data])


    return (
        <div id="error-column" className="refund-quote-column error-column error-animate">
            <h2 id="error-title" className="error-title" >ERRORS</h2>
            {
                errors.map((value,index)=>{
                    return(<h4 key={value}>{value}< br/></h4>)
                })
            }

        </div>
    )


}
//  "test": "react-scripts test",

export default CurrentAssignmentDetails
