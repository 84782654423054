
export const CUSTOMER_FIELDS=["VIN","Customer Name","Ending Miles","Dealer Name"]
export const CUSTOMER_JSON_FIELDS=["assignment.vin","customerName","endingMileage","dealerName"]

export const PRODUCT_FIELDS=["Type","Policy/Contract","Effective Date","Cancellation Effective Date"]
export const PRODUCT_JSON_FIELDS=["assignment.productType","assignment.insurancePolicyNumber","effectiveDate","cancelDate"]

export const REFUND_FIELDS=["Customer Cost","Customer Refund Due","Reason for $0 Amount","Request Date/Time","Date/Time Completed","Provider ID"]
export const REFUND_JSON_FIELDS=["customerCost","rebateAmount","noRefundReason","assignment.requestDate","assignment.otherDate","providerId"]

export const FOOTER_TEXT="Copyright © 2020 Ford Motor Credit Company. All Rights Reserved.   Cookie Settings"

export const SIDEBAR_TEXT_1=`All information submitted via this website is subject to the terms and conditions of your Add-on
                                       Provider Agreement with Ford Motor Credit Company LLC.
                                       Under the terms of your Add-on Provider Agreement all applicable refund amounts must be provided
                                       within 1 business day.`

export const SIDEBAR_TEXT_2=`To access Privacy Policy link from the pop-up footer, right click on link and select “Open in New
                        Window”.
                        Note: Once you accept, the footer will no longer appear.`

export const STATE_NAMES=[{id:"AL",name:"Alabama"}, {id:"AK",name:"Alaska"}, {id:"AZ",name:"Arizona"}, {id:"AR",name:"Arkansas"}, {id:"CA",name:"California"}, {id:"CO",name:"Colorado"}, {id:"CT",name:"Connecticut"}, {"DE":"Delaware"}, {id:"DC",name:"District of Columbia"},
    {id:"FL",name:"Florida"}, {id:"GA",name:"Georgia"}, {id:"GU",name:"Guam"}, {id:"HI",name:"Hawaii"}, {"ID":"Idaho"}, {id:"IL",name:"Illinois"}, {id:"IN",name:"Indiana"}, {id:"IA",name:"Iowa"}, {id:"KS",name:"Kansas"}, {id:"KY",name:"Kentucky"},
    {id:"LA",name:"Louisiana"}, {id:"ME",name:"Maine"}, {id:"MD",name:"Maryland"}, {id:"MA",name:"Massachusetts"}, {id:"MI",name:"Michigan"}, {id:"MN",name:"Minnesota"}, {id:"MS",name:"Mississippi"}, {id:"MO",name:"Missouri"}, {id:"MT",name:"Montana"},
    {id:"NE",name:"Nebraska"}, {id:"NV",name:"Nevada"}, {id:"NH",name:"New Hampshire"}, {id:"NJ",name:"New Jersey"}, {id:"NM",name:"New Mexico"}, {id:"NY",name:"New York"}, {id:"NC",name:"North Carolina"}, {id:"ND",name:"North Dakota"}, {id:"OH",name:"Ohio"},
    {id:"OK",name:"Oklahoma"}, {id:"OR",name:"Oregon"}, {id:"PA",name:"Pennsylvania"}, {id:"PR",name:"Puerto Rico"}, {id:"RI",name:"Rhode Island"}, {id:"SC",name:"South Carolina"}, {id:"SD",name:"South Dakota"}, {id:"TN",name:"Tennessee"}, {id:"TX",name:"Texas"}, {id:"VI",name:"Virgin Islands"},
    {id:"UT",name:"Utah"}, {id:"VT",name:"Vermont"}, {id:"VA",name:"Virginia"}, {id:"WA",name:"Washington"}, {id:"WV",name:"West Virginia"}, {id:"WI",name:"Wisconsin"}, {id:"WY",name:"Wyoming"}]