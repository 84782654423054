import React, {useEffect, useState} from 'react'
import {useLoaderData, useNavigate} from "react-router-dom";
import "../css/updateProfileDetails.css"
import {STATE_NAMES} from "../utils/constants";
import {providerProfileSave} from "../services/api/providerProfileApi";
import {validateEmail, validateZipCode} from "../utils/utils";
import {ProviderProfileDetail} from "../components/applicationObjects";



function UpdateProfileDetails()
{

    const data : ProviderProfileDetail  =useLoaderData() as ProviderProfileDetail;

    const [providerProfile,setProviderProfile]=useState({} as ProviderProfileDetail)
    const navigate =useNavigate()
    function handleCancel()
    {
        navigate("/updateProfileList")
    }

    function handleSubmit()
    {
        providerProfileSave(providerProfile)
        navigate("/updateProfileList")

    }

    // @ts-ignore
    function handleDataChange(e)
    {
        setProviderProfile((prev: any)=>{
        let helper= {...prev}
            console.log(helper)
            console.log("e.target.id-->"+e.target.id)
            console.log("e.target.value-->"+e.target.value)
        let target_id=e.target.id
            console.log("helper[target_id]-->"+helper[target_id])
            if(e.target.type ==='radio')
            {
                helper["emailNotification"]=e.target.id=="emailNotification_no"?false:true
            }
            else
            {
                helper[target_id]=e.target.value
            }
            return helper
      })

        console.log(providerProfile)

        if(e.target.id==="zipCd" || e.target.id==="rbtEmailTx" || e.target.id==="emailNotification_yes")
        {
            let element=e.target.id==="emailNotification_yes"? "rbtEmailTx": e.target.id
            handleValidation(element)
        }

         if(e.target.name ==="email_notification")
         {
             if(e.target.id==="emailNotification_yes")
             {
                 setProviderProfile((prev:any)=>{return {...prev,"emailNotification":true}})
             }
             else
             {
                 setProviderProfile((prev:any)=>{return {...prev,"emailNotification":false}})
                 // @ts-ignore
                 if(document.getElementById("rbtEmailTx").value.length>0)
                 {
                     handleValidation("rbtEmailTx")
                 }
                 else
                 {
                     // @ts-ignore
                     document.getElementById("profile-update").style.cssText ="cursor:pointer; pointer-events:auto; opacity:1"
                     // @ts-ignore
                     document.getElementById("rbtEmailTx").nextElementSibling.style.visibility="hidden"

                 }
             }
         }

        console.log(providerProfile)

    }


    function determineErrorDisplayAndUpdateButtonEnable(validation: boolean, e: string)
    {
        if(validation)
        {
            // @ts-ignore
            document.getElementById(e).nextElementSibling.style.visibility="visible"
            // @ts-ignore
            document.getElementById("profile-update").style.cssText ="cursor:not-allowed; pointer-events:none; opacity:0.6"
        }
        else
        {
            // @ts-ignore
            document.getElementById(e).nextElementSibling.style.visibility="hidden"
            // @ts-ignore
            document.getElementById("profile-update").style.cssText ="cursor:pointer; pointer-events:auto; opacity:1"
        }
    }

    // @ts-ignore
    function handleValidation(e)
    {
       let validation= false

        // @ts-ignore
        let elementValue=document.getElementById(e).value
        // @ts-ignore
        console.log(document.getElementById(e).value)
             if(e==="zipCd" )
             {
                 // @ts-ignore
                 validation= !validateZipCode(elementValue)
                 determineErrorDisplayAndUpdateButtonEnable(validation,e)

             }else { // @ts-ignore
                 if(e==="rbtEmailTx")
                              {
                                  // @ts-ignore
                                  if(!document.getElementById("emailNotification_no").checked)
                                  {
                                      validation= !validateEmail(elementValue)
                                  }
                                  else { // @ts-ignore
                                      if(document.getElementById("emailNotification_no").checked && elementValue.length>0)
                                                                        {
                                                                            validation= !validateEmail(elementValue)
                                                                        }
                                  }
                              }
                 determineErrorDisplayAndUpdateButtonEnable(validation,e)
             }

        }

    useEffect(()=>{

        setProviderProfile(data)

    },[])



    return(
        <div className="update-profile-container">
          <h2>Update Profile</h2>
            {
                providerProfile!=null &&
                (
                    <>
                        <table className="update-profile">
                            <tr>
                                <td>Address:</td>
                                <td><input id="addrLine1Tx" type="text"
                                           onChange={handleDataChange} maxLength={40}
                                           value={providerProfile.addrLine1Tx}/></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><input id="addrLine2Tx" type="text"
                                           onChange={handleDataChange} maxLength={40}
                                           value={providerProfile.addrLine2Tx}/> (Optional)
                                </td>
                            </tr>
                            <tr>
                                <td>City:</td>
                                <td><input id="cityNm" type="text"
                                           onChange={handleDataChange} maxLength={30} value={providerProfile.cityNm}/>
                                </td>
                            </tr>
                            <tr>

                                {providerProfile.countryCode === "USA" ? <td> State:</td> : <td> Province:</td>}
                                <td><select id="state" className="state-selectbox" onChange={handleDataChange}
                                            value={providerProfile.state}>
                                    {
                                        STATE_NAMES.map((value, index) => {
                                            return (<option key={value.id} value={value.id}>{value.name}</option>)
                                        })
                                    }
                                </select></td>
                            </tr>
                            <tr>
                                {providerProfile.countryCode === "USA" ? <td>Zip Code:</td> : <td>Postal Code:</td>}
                                <td><input id="zipCd" type="text" placeholder="99999-9999" onChange={handleDataChange}
                                           maxLength={11} value={providerProfile.zipCd}/> <p
                                    className="zip-error-message">Invalid Zip Code</p></td>
                            </tr>
                        </table>
                        <h3>Refund Contact Information</h3>
                        <hr className="separator"/>

                        <table className="update-profile">
                            <tr>
                                <td>Phone Number:</td>
                                <td><input id="rbtPhnAreaCd" type="text" size={3} onChange={handleDataChange} placeholder="999"
                                           maxLength={3} value={providerProfile.rbtPhnAreaCd}/>
                                    <input id="rbtPhNb" type="text" size={9} onChange={handleDataChange} placeholder="999999999"
                                           maxLength={7} value={providerProfile.rbtPhNb}/>
                                    Ext:<input id="rbtPhExtnNb" type="text" size={5} onChange={handleDataChange} placeholder="99999"
                                               maxLength={5} value={providerProfile.rbtPhExtnNb}/>
                                </td>
                            </tr>
                            <tr>
                                <td>Email Address:</td>
                                <td><input id="rbtEmailTx" type="text" size={23}
                                           onChange={handleDataChange} maxLength={40}
                                           value={providerProfile.rbtEmailTx}/> <p
                                    className="email-error-message">Invalid Email Address</p></td>
                            </tr>
                            <tr>
                                <td>Would you like to receive Email Notification?</td>
                                <td><input id="emailNotification_yes" type="radio" name="email_notification"
                                           onChange={handleDataChange} value="true"
                                           checked={providerProfile.emailNotification}/> Yes
                                    <input id="emailNotification_no" type="radio" name="email_notification"
                                           onChange={handleDataChange} value="false"
                                           checked={!(providerProfile.emailNotification)}/> No
                                </td>
                            </tr>
                        </table>
                    </>
                )
            }
                    <button id="profile-update" className="update" onClick={handleSubmit}>Update</button>
                    <button className="cancel" onClick={handleCancel}>Cancel</button>
        </div>
    )
}

export default UpdateProfileDetails;
