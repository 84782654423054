import {WebClient} from "../../utils/utils";
const log = require("debug")("ClosedAssignments");

let axios = new WebClient();

export const closedAssignmentListDataLoader=async ()=>{
    const {data} =  await axios.get("CLOSED_LIST");
    if(log.enabled) {
        log("Closed List: %o",data);
    }
    return data;
}


export const closedAssignmentsDetailDataLoader=async ({params,request})=>{
    const searchParams = new URL(request.url).searchParams;
    const {data} =  await axios.get("CLOSED_DETAILS",searchParams.get("bpi"));
    if(log.enabled) {
        log("Detail for BPI %s: %o",searchParams.get("bpi"),data);
    }
    return data;
}
