import React from 'react'
import '../css/footer.css'
import {FOOTER_TEXT} from "../utils/constants";

function Footer()
{
    return (
        <div className="footer">
            <p>{FOOTER_TEXT}</p>
        </div>
    )
}


export default Footer