import React, { useEffect, useState } from 'react';
import '../css/supplierList.css'
import { setCurrentSupplier } from '../services/auth/LoginModule';
import {supplierDataLoader} from "../services/api/suppliersApi"
import {troubleshootingMode} from "../utils/troubleshooter";

const debug = require("debug-browserify")("SupplierList");
const error = require("debug-browserify")("SupplierList:error")
function SupplierList() {
  const [  loading, setLoading ] = useState(true);
  const [data , setData] = useState([])
  const [filter, setFilter] = useState('');
  let supplier = -1;

  function handleLogin()
  {
    setCurrentSupplier(data.filter(d => { return d.id === supplier })[0].code).then(() => {
      window.location.reload();
    }).catch((err ) => {
      error(err);
      alert("Unable to login.");
    });
  }

  function updateSupplier(e)
  {
    debug("Updating supplier",e);
    supplier = Number(e.target.value);
  }


  useEffect(()=>{
    const fetchData = async () => {
      setLoading(true);
      try {
        await setCurrentSupplier(null);
        let resp = await supplierDataLoader();
        resp.unshift({'code':'ALLAV','name':'All Available',id:-1})
        setData(resp);
      } catch(err) {
        error("Unable to get providers list.",err);
        alert("Unable to get providers list.")

      }
    }
    fetchData().then(() => setLoading(false));
  },[]);
  return (<div><h3>Internal Support Page</h3>
    {loading && <div className="main">Loading Providers...<a href={"#"} onClick={troubleshootingMode}>Enable Troubleshooting Mode</a> </div>}
    {!loading && (
        <div className="main">
          <div><strong className="label">Search by Name or GSDB:</strong><input id="filter" name="filter" type="text"
                                                                                value={filter} onChange={event => setFilter(event.target.value)}/>
          </div>
          <div className="divider"></div>
          <div>
            <strong className="label">Provider Name:</strong>
            <select id="provider" onChange={updateSupplier}>
              {
                data.filter(f => filter === '' || f.name.toLowerCase().search(filter.toLowerCase()) >= 0 || f.code === filter.toUpperCase()).map((tempData, index) => {
                  if (index === 0) {
                    supplier = tempData.id;
                  }
                  return (<option key={tempData.id} value={tempData.id}>{tempData.name} ({tempData.code})</option>);
                })
              }
            </select>
          </div>
          <div className="divider"></div>
          <div className="divider"></div>
          <div className="centerButton">
            <button className="login" onClick={handleLogin}>Submit</button>
          </div>
          <p><a href={"#"} onClick={troubleshootingMode}>Enable Troubleshooting Mode</a></p>
        </div>
    )}
  </div>)

}

export default SupplierList;