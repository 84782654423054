import axios from "axios";
import {updateURL} from "../services/auth/LoginModule";
const isLocal= process.env.REACT_APP_ENV === "local"
const host =process.env.REACT_APP_GATEWAY_URL;
const contextPath = (host.indexOf("/",8) > 0) ? host.substring(host.indexOf("/",8))  : "";

const log = require('debug')("utils");
const warn= log.extend("warn");
warn.enabled=true
warn.log = console.warn.bind(console);

export const insecureMode = function()
{
    const isInsecureMode= process.env.REACT_APP_OVERRIDE_LOGIN === "true";
    if(isInsecureMode && localStorage.getItem("InsecureWarning") === null) {
        warn("RUNNING IN INSECURE MODE!!!!")
        localStorage.setItem("InsecureWarning","you have been warned")
    } else {
        log("Insecure Mode: %s",isInsecureMode);
    }
    return isInsecureMode;
}

if(!insecureMode()) {
    log("Sending axios info with credentials");
    axios.defaults.withCredentials = true
}

export function processObjects(keyField,jsonKeyField,data){
    const processData=[]
    keyField.forEach((value,index)=>{
        if(jsonKeyField[index].includes("."))
        {
            processData.push([value,data[jsonKeyField[index].split(".")[0]][jsonKeyField[index].split(".")[1]]])
        }
        else
        {
            processData.push([value,data[jsonKeyField[index]]])
        }
    })

    return processData
}

export function validateZipCode(value){
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
}

export function validateEmail(value) {
   return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
}

export function validateNumber(value){

let validNumber=true;
    if(!isNaN(value))
    {
        if(value.includes("."))
        {
            if(value.substring(value.indexOf(".")+1).length!==2)
            {
                validNumber=false;
            }

       }
        else
        {
            if(value.length>11)
            {
                validNumber=false;
            }
        }
        console.log("dot found:"+value.includes("."))
    }
    else {
        validNumber=false
    }
    return validNumber
}

const convertURL = function(url) {
    if(isLocal) {
        url = url.replaceAll("/","_");
    }
    return updateURL(new URL(contextPath+url,host).toString());

}

export function getApiUrl(api,params)
{
    log(process.env.NODE_ENV)
    let url=api;

    switch(api)
    {
        case "CURRENT_LIST":
             url=convertURL("/api/v1/assignment/current")
            break;
        case "CURRENT_DETAILS":
             url =isLocal ? "http://localhost:8000/currenAssignmentDetails?teamworkBpiNb="+params : updateURL(host+"/api/v1/assignment/"+params)
            log(url)
            break;
        case "CURRENT_SAVE":
            url= isLocal ? "http://localhost:8000/currentAssignmentSave/251f" : updateURL(host+"/api/v1/assignment/save")
            break;
        case "CLOSED_LIST":
            url = convertURL("/api/v1/assignment/closed")
            break;
        case "CLOSED_DETAILS":
            url = isLocal ? "http://localhost:8000/closedAssignmentDetails?teamworkBpiNb="+params : updateURL(host+"/api/v1/assignment/"+params)
            break;
        case "PROFILE_LIST":
            url = convertURL("/api/v1/provider/suppliers")
            break;
        case "PROFILE_DETAILS":
            url = isLocal ? "http://localhost:8000/providerProfileDetails?gsdb="+params : updateURL(host+"/api/v1/provider/supplier/"+params)
            break;
        case "PROFILE_SAVE":
            url = isLocal ? "http://localhost:8000/providerProfileDetails/1187" : updateURL(host+"/api/v1/provider/supplier")
            break;
        case "PROVIDER_LIST":
            url = convertURL("/api/v1/provider/list");
            break;
        default:
         break;
    }
    return url
}

const isRedirect = function(response) {
    return (response && response.status === 401 && response.data && response.data.loginURL);
}

const handleError = function(err) {

    log("Error calling API. Error: %o",err);
    if(isRedirect(err.response)) {
        window.location.assign(err.response.data.loginURL);
    } else {
        warn("Error calling API. Error: %o",err);
        throw err;
    }

}

export class WebClient {

    #getURL(url,params=null) {
        let _url = url;
        if (url.indexOf("/") === -1) {
            _url = getApiUrl(url, params)
        }
        if (!_url.startsWith(process.env.REACT_APP_GATEWAY_URL)) {
            _url = process.env.REACT_APP_GATEWAY_URL + _url;
        }
        return _url;
    }
    get(url,params=null,config=null)  {
        return axios.get(this.#getURL(url,params),config).catch(handleError);
    }
    put(url,payload=null,config=null) {
        return axios.put(this.#getURL(url),payload,config).catch((err) => {
            //Backup data before redirect?
            handleError(err);
        });

    }
    post(url,payload=null,config=null) {
        return axios.post(this.#getURL(url),payload,config).catch((err) => {
            //Backup data before redirect?
            handleError(err);
        });
    }
}