import React, {useEffect, useState} from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {AssignmentList, ProviderProfileList, } from "../components/applicationObjects";
import "../css/updateProfile.css";
// @ts-ignore
import {v4 as uuidv4} from 'uuid';


const log = require('debug')("ProfileList");

function UpdateProfileList()
{

    const data : ProviderProfileList =useLoaderData() as ProviderProfileList;
    const [providerProfiles,setproviderProfiles]=useState([])


    const navigate =useNavigate()
    // @ts-ignore
    function handleClick(gsdb)
    {
        log("Using GSDB: %s",gsdb)
        navigate("/updateProfileDetails?gsdb="+gsdb)
    }

    useEffect(()=>{
        // @ts-ignore
        setproviderProfiles(data)
        log("Provider Profiles list:%o",data)
    },[])

    return(
        <div className="profile-list-data-container">
            <h2>Provider Profiles</h2>
            <ul className="profile-list">
                {
                    providerProfiles.map((value: any, index: any)=> {
                        return(<li key={uuidv4()} onClick={()=>handleClick(value.id)}>{value.name}</li>)
                    })
                }
            </ul>
        </div>
    )
}

export  default UpdateProfileList