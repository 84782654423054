import {WebClient} from "../../utils/utils";
let axios = new WebClient();

export const supplierDataLoader=async ()=>{
    try {
      const { data } =  await axios.get("PROVIDER_LIST");
      return data;
    } catch(e) {
      console.log(e);
    }
}
