import React, {useState} from 'react'
import "../css/sidebar.css"
import "../css/sidebar.css"
import {Link, Outlet} from "react-router-dom";
import {SIDEBAR_TEXT_1, SIDEBAR_TEXT_2} from "../utils/constants";

function Sidebar()
{
    return (
        <>
            <div className="sidebar">
                <div className="sidebar-content">
                    <ul className="sidebar-menu">
                        <li><Link to="/">Current Assignments</Link></li>
                        <li><Link to="/updateProfileList">Update Profile</Link></li>
                        <li><Link to="/closedAssignment">Closed Assignments</Link></li>
                    </ul>

                    <br/>
                    <br/>
                    <p>
                        {SIDEBAR_TEXT_1}
                    </p>
                    <br/>
                    <br/>
                    <p className="sidebar-text-2">
                        {SIDEBAR_TEXT_2}
                    </p>
                </div>
                <div className="data-container">
                    <Outlet />
                </div>
            </div>


        </>
    )
}

export default Sidebar