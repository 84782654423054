import React, {useEffect, useState} from 'react'
import {AssignmentList} from "../components/applicationObjects";
import {useLoaderData} from "react-router-dom";
import AssignmentTable from "../components/AssignmentTable";

function CurrentAssignments()
{
    const data : AssignmentList =useLoaderData() as AssignmentList;
    const currentAssignmentDetailsLink="currentAssignmentsDetails";

    const [newAssignments,setNewAssignments]=useState([])
    const [pendedAssignments,setPendedAssignments]=useState([])
    const [expiredAssignments,setExpiredAssignments]=useState([])


    function processAssignmentList()
    {
        // @ts-ignore
        data.map((value: any, index: any)=>{
            if (value.status==='Active')
            {
                // @ts-ignore
                setNewAssignments(newAssignments=>[...newAssignments,value])
            }
            else if (value.status==='Pended')
            {
                // @ts-ignore
                setPendedAssignments(pendedAssignments=>[...pendedAssignments,value])
            }
            else if(value.status==='Expired')
            {
                // @ts-ignore
                setExpiredAssignments(expiredAssignments=>[...expiredAssignments,value])
            }
        })
    }

    useEffect(()=>{
        console.log(process.env)
        processAssignmentList();
    },[])

    return (
        <div>
            <h2 className="currentAssignmentTitle">Current Assignments</h2>
            <AssignmentTable name={"New"} data={newAssignments} navigateTo={currentAssignmentDetailsLink} datalength={data.length}/>
             <AssignmentTable name={"Pended"} data={pendedAssignments} navigateTo={currentAssignmentDetailsLink} datalength={data.length}/>
             <AssignmentTable name={"Expired"} data={expiredAssignments} navigateTo={currentAssignmentDetailsLink} datalength={data.length}/>
        </div>
    )
}
export default CurrentAssignments