import {WebClient} from "../../utils/utils";
let axios = new WebClient();

export const providerProfilesDataLoader=async ()=>{
    const {data} =  await axios.get("PROFILE_LIST");
    return data;
}

export const providerProfileDetailsDataLoader=async ({params,request})=>{
    const searchParams = new URL(request.url).searchParams;
    const {data} =  await axios.get("PROFILE_DETAILS",searchParams.get("gsdb"));
    return data;
}

export const providerProfileSave=async (payload)=>{
    await axios.put("PROFILE_SAVE",payload);
}
