import {useLoaderData, useRouteError} from "react-router-dom";
import {useEffect, useState} from "react";
import "../css/errorBoundary.css"


function ErrorBoundary()
{
    const error =useRouteError()
    const [errorMessage,setErrorMessage]=useState("There is some unexpected failure in the service, please try after some time.")
    const status=useLoaderData()

    useEffect(()=>{
        console.log(error)
        if(status==="Page Not Found")
        {
            setErrorMessage("Page Not Found")
        }
        console.log(status)
    },[])

    return(<div>
        <h1 className="errorpage-message">{errorMessage}</h1>
    </div>)
}

export default ErrorBoundary