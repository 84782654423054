import {WebClient} from "../../utils/utils";
let axios = new WebClient();


export const currentAssignmentsListDataLoader=async ()=>{
    const {data} =  await axios.get("CURRENT_LIST");
    return data;
}

export const currentAssignmentsDetailDataLoader=async ({params,request})=>{
    const searchParams = new URL(request.url).searchParams;
    console.log(request)
    console.log(searchParams)
    console.log(searchParams.get("bpi"))
    const {data} =  await axios.get("CURRENT_DETAILS",searchParams.get("bpi"));
    return data;
}

export const currentAssignmentSave=async(payload) =>{
    try
    {
        await axios.put("CURRENT_SAVE", payload)
    }
    catch(error)
    {
        throw new Error(error)
    }
}
