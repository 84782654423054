import React from 'react'
import '../css/help.css'
import {troubleshootingMode} from "../utils/troubleshooter";

function Help()
{
    return(
        <div>
            <h2 className="help-title">Help Info</h2>
            <div className="help-info">
                <p color="red"><b><i>Provider Web Applications is best viewed with a screen resolution of 1920 X 1080.<br></br>
                    If that is not available, you can update your browser zoom to 80%.</i></b></p>
                <p><b>Current Assignments: </b>
                    Lists all active assignments in three categories:</p>
                <ul className="help-assignment-list">
                    <li> New: Assignments are displayed here for 1 business day
                    </li>
                    <li> Pended: Assignments are displayed here if additional information is required (per check-box in
                        "Assignment Detail")
                    </li>
                    <li> Expired: Assignments that have not been worked in 1 business day will be displayed here
                    </li>
                </ul>

                <p><b>Filter/Search: </b>
                    User can filter/search by either: VIN number, Policy/Contract, or Product Type</p>

                <p><b>Update Profile: </b>
                    User can update refund contact information and email notification preferences</p>

                <p><b>Closed Assignments: </b>
                    List of processed assignments over the last 30 days</p>
                <p><b>Assignment Detail: </b>
                    Displays when user clicks on a Current Assignment.</p>
                <ul className="help-assignment-list">
                    <li> Amount: Enter Ford Motor Credit customer's refund quote amount in this field
                    </li>
                    <li> Reason for $0 Amount: If refund quote amount entered is $0, user must select from this dropdown
                    </li>
                    <li> Additional Info Reqd?: Check this box if user would like to be contacted by Ford Credit for
                        this
                        assignment
                    </li>
                </ul>
                <p><a href={"#"} onClick={troubleshootingMode}>Toggle Troubleshoot Mode</a></p>
            </div>
        </div>
    )
}

export default Help;