import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import React, {useEffect, useState} from "react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import ClosedAssignments from "./pages/ClosedAssignments";
import UpdateProfileDetails from "./pages/UpdateProfileDetails";
import {
    currentAssignmentsDetailDataLoader,
    currentAssignmentsListDataLoader
} from "./services/api/currentAssignmentApi";
import CurrentAssignments from "./pages/CurrentAssignments";
import Footer from "./components/Footer";
import UpdateProfileList from "./pages/UpdateProfileList";
import ClosedAssignmentDetails from "./pages/ClosedAssignmentDetails";
import {closedAssignmentListDataLoader, closedAssignmentsDetailDataLoader} from "./services/api/closedAssignmentApi";
import CurrentAssignmentDetails from "./pages/CurrentAssignmentDetails";
import {providerProfilesDataLoader, providerProfileDetailsDataLoader} from "./services/api/providerProfileApi";
import { isProfileSet, isUserAuthenticated, isUserInternal } from './services/auth/LoginModule';
import SupplierList from './pages/SupplierList';
import ErrorBoundary from "./pages/ErrorBoundary";

const log = require("debug")("App");
function App() {

    const [loading,setLoading] = useState(true)
    const [isInternal,setInternal] = useState(false);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [hasProfile, setHasProfile] = useState(false);
    useEffect(() => {
        checkUserInternal();
    },[])

    const checkUserInternal = async () => {
        try {
            let authenticated = await isUserAuthenticated();
            setAuthenticated(authenticated);
            let existingProfile = false;
            if(authenticated) {
                existingProfile =await isProfileSet();
                setHasProfile(existingProfile);
            }
            if(!existingProfile) {
                setInternal(await isUserInternal());
            }
            setLoading(false);
        } catch(e) {
            // log("Unable to set ")
            setInternal(false);
        }

    }


    //This is loader for page not found # changes to resolve build issue

    function pageNoFound() {
        return "Page Not Found"
    }

    if(isAuthenticated && !loading) {
        if(hasProfile) {
            const router = createBrowserRouter(
                createRoutesFromElements(
                    <Route path="/" element={<Sidebar />} errorElement={<ErrorBoundary />} >
                        <Route errorElement={<ErrorBoundary />}>
                            <Route index element={<CurrentAssignments/>} loader={currentAssignmentsListDataLoader}  />
                            <Route path="/currentAssignmentsDetails" element={<CurrentAssignmentDetails />} loader={currentAssignmentsDetailDataLoader}  />
                            <Route path="/closedAssignment" element={<ClosedAssignments />} loader={closedAssignmentListDataLoader}   />
                            <Route path="/closedAssignmentsDetails" element={<ClosedAssignmentDetails />} loader={closedAssignmentsDetailDataLoader}  />
                            <Route path="/updateProfileList" element={<UpdateProfileList />} loader={providerProfilesDataLoader}  />
                            <Route path="/updateProfileDetails" element={<UpdateProfileDetails />} loader={providerProfileDetailsDataLoader}   />
                            <Route path="/logoff" element={<SupplierList />} />
                            <Route path="/error" element={<ErrorBoundary />}  />
                            <Route path="/*" element={<ErrorBoundary />} loader={pageNoFound} />
                        </Route>
                    </Route>
                )
            )
            return (
                <>
                    <Navbar/>
                    <RouterProvider router={router}/>
                    <Footer/>
                </>

            );
        } else if(isInternal) {
            return (
                <>
                    <SupplierList />
                </>
            )
        } else {
            return (<><div>You are not authorized.</div></>)
        }
    } else {
        return (<><div>Please hold on. Logging you in.</div></>)
    }
}


export default App;
