import React, {useEffect, useState} from 'react'
import AssignmentDetailTable from "../components/AssignmentDetailTable";
import {useLoaderData, useNavigate, useSearchParams} from "react-router-dom";
import "../css/closedAssignmentDetails.css"
import {processObjects} from "../utils/utils";
import {CUSTOMER_FIELDS, CUSTOMER_JSON_FIELDS, PRODUCT_FIELDS, PRODUCT_JSON_FIELDS,REFUND_FIELDS,REFUND_JSON_FIELDS} from "../utils/constants";
import {v4 as uuidv4} from 'uuid';

function ClosedAssignmentDetails()
{

    const data  =useLoaderData();
    const [customerData,setCustomerData]=useState([])
    const [productData,setProductData]=useState([])
    const [refundDataTop,setRefundDataTop]=useState([])
    const [refundDataBottom,setRefundDataBottom]=useState([])
    const [deductionAvailable,setDeductionAvailable]=useState(false)
    const [tableDeductions,setTableDeductions] =useState(new Map([
        ["ProRated Term/Miles",{"amount":0,"description":""}],
        ["Paid Claims",{"amount":0,"description":""}],
        ["Cancellation Fee",{"amount":0,"description":""}],
        ["Other",{"amount":0,"description":""}]
    ]))

    const navigate =useNavigate()



    function buildCustomerData(assignmentDetail)
    {
        let assignmentDetailCustomerData
        if(assignmentDetail!=null)
        {
            assignmentDetailCustomerData= processObjects(CUSTOMER_FIELDS,CUSTOMER_JSON_FIELDS,assignmentDetail)
            setCustomerData(customerData=>[...assignmentDetailCustomerData])
        }
    }

    function buildProductData(assignmentDetail)
    {
        let assignmentDetailProductData
        if(assignmentDetail!=null) {
            assignmentDetailProductData= processObjects(PRODUCT_FIELDS,PRODUCT_JSON_FIELDS,assignmentDetail)
            setProductData(productData=>[...assignmentDetailProductData])
        }
    }

    function buildRefundData(assignmentDetail)
    {
        let assignmentDetailRefundData


        if(assignmentDetail.deductions.length>0)
        {
            setDeductionAvailable(true)
            const tableDeductionsMap=new Map(tableDeductions)

            for(let i=0;i<assignmentDetail.deductions.length;i++)
            {
                let assignmentDeduction=assignmentDetail.deductions[i]
                tableDeductionsMap.set(assignmentDeduction.type,{"amount":assignmentDeduction.amount,"description":assignmentDeduction.description})

            }
            setTableDeductions(tableDeductionsMap)

        }

        if(assignmentDetail!=null) {
            assignmentDetailRefundData= processObjects(REFUND_FIELDS,REFUND_JSON_FIELDS,assignmentDetail)

            for (let i=0;i<assignmentDetailRefundData.length;i++)
            {
                if(assignmentDetailRefundData[i][0]==="Customer Cost" || assignmentDetailRefundData[i][0]==="Customer Refund Due")
                {
                    assignmentDetailRefundData[i][1]="$"+ assignmentDetailRefundData[i][1].toFixed(2)
                }
            }

            let refundTop=assignmentDetailRefundData[0]
            setRefundDataTop(productData=>[refundTop])


            let refundBottom=assignmentDetailRefundData.slice(1)
            setRefundDataBottom(productData=>[...refundBottom])


        }
        if(assignmentDetailRefundData!=null)
        {
            console.log(assignmentDetailRefundData)
        }
    }

    useEffect(()=>{
        buildRefundData(data)
        buildCustomerData(data)
        buildProductData(data)
    },[])


function handleCancel()
{
    navigate("/closedAssignment")

}

    return(
        <div className="assignmentDetail-container">
            <h3 className="topMessage">***PROVIDE REFUND QUOTE BASED ON FORD CREDIT'S CUSTOMER COST (NOT DEALER
                COST)***</h3>
            <h2>Assignment Detail</h2>
            <AssignmentDetailTable data={customerData}/>
            <h3>Product Details for Refund Quote</h3>
            <hr/>
            <AssignmentDetailTable data={productData}/>
            <h3>Refund Quote</h3>
            <hr/>
                <table className="assignment-refund-quote closed-assignment-refund-quote">
                    {
                        refundDataTop.map((value,index)=>{
                            return (<tr key={uuidv4()}>
                                <td>{value[0]}:</td>
                                <td>{value[1]}</td>
                            </tr>);
                        })

                    }
                    {deductionAvailable ?
                    <tr>
                        <td>Deductions:</td>
                        <td>
                            <table className="closed-assignment-deductions">
                                <thead>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Other Description</th>
                                </thead>
                                {
                                    Array.from(tableDeductions.entries()).map(([key,value],index)=>{
                                        return(
                                            <tr key={uuidv4()}>
                                                <td style={{"border": "1px solid black"}}>{key}</td>
                                                <td style={{"border": "1px solid black"}}>{value.amount>0 ?value.amount:""}</td>
                                                <td style={{"border": "1px solid black","word-wrap":"break-word"}}>{value.description}</td>
                                            </tr>
                                        )
                                        }
                                    )
                                }

                            </table>
                        </td>
                    </tr> : <></> }
                    {
                        refundDataBottom.map((value,index)=>{
                            return (<tr key={uuidv4()}>
                                <td>{value[0]}:</td>
                                <td>{value[1]}</td>
                            </tr>);
                        })

                    }
                </table>
            <button onClick={handleCancel}>Back</button>
        </div>
    )
}

export default ClosedAssignmentDetails