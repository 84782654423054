import React, {useEffect, useState} from 'react'
import AssignmentTable from "../components/AssignmentTable";
import {AssignmentList} from "../components/applicationObjects";
import {useLoaderData} from "react-router-dom";


function ClosedAssignments()
{
    const data : AssignmentList =useLoaderData() as AssignmentList;

    const closedAssignmentDetailsLink="closedAssignmentsDetails";
    const [fromProviderAssignments,setFromProviderAssignments]=useState([])
    const [manuallyEnteredAssignments,setManuallyEnteredAssignments]=useState([])
    const [noResponseAssignments,setNoResponseAssignments]=useState([])

    function processClosedAssignmentList()
    {
        // @ts-ignore
        data.map((value: any, index: any)=>{
            if (value.status==='fromProvider')
            {
                // @ts-ignore
                setFromProviderAssignments(fromProviderAssignments=>[...fromProviderAssignments,value])
            }
            else if (value.status==='manuallyEntered')
            {
                // @ts-ignore
                setManuallyEnteredAssignments(manuallyEnteredAssignments=>[...manuallyEnteredAssignments,value])
            }
            else if(value.status==='noResponse')
            {
                // @ts-ignore
                setNoResponseAssignments(noResponseAssignments=>[...noResponseAssignments,value])
            }
        })
    }

    useEffect(()=>{
        console.log(data)
        processClosedAssignmentList();
    },[])

    return(
        <div>
            <div>
                <h2 className="closedAssignmentTitle">Closed Assignments</h2>
                <AssignmentTable name={"Entered by Provider"} data={fromProviderAssignments} navigateTo={closedAssignmentDetailsLink} datalength={data.length}/>
                <AssignmentTable name={"Entered by Ford Credit"} data={manuallyEnteredAssignments} navigateTo={closedAssignmentDetailsLink} datalength={data.length}/>
                <AssignmentTable name={"No Response"} data={noResponseAssignments} navigateTo={closedAssignmentDetailsLink} datalength={data.length}/>
            </div>
        </div>)

}

export default ClosedAssignments