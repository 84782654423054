import React, {useEffect, useState} from "react";
import '../css/assignmentTable.css'
import {Assignment} from "./applicationObjects";
import {useNavigate} from "react-router-dom";


// @ts-ignore
function AssignmentTable({name,data,navigateTo,datalength})
{
    const tabledata :[Assignment] = data
    const navigate =useNavigate()
    const [closeExpireColumn,setCloseExpireColumn]=useState("")
    const [containerHeight,setContainerHeight]=useState({})
    // @ts-ignore
    function handleClick(bpi)
    {
        console.log("navigation:","/",navigateTo,"?bpi=",bpi)
        navigate("/"+navigateTo+"?bpi="+bpi)
    }

 function determineCloseOrExpirationColumn()
 {
     if(navigateTo==="currentAssignmentsDetails")
     {
         setCloseExpireColumn("Expiration Date/Time (EST)")
         setContainerHeight({
             "marginLeft": "70px",
              "marginTop": "30px",
               "height":"100%",
         })
     }
     else
     {
         setCloseExpireColumn("Closed Date/Time (EST)")
         let height=""
         if(datalength==1)
         {
             height="150px"
         }
         else if(datalength>1 && datalength <4)
         {
             height="180px"
         }
         else if(datalength>=4)
         {
             height="250px"

         }

         setContainerHeight({
             "marginLeft": "70px",
             "marginTop": "70px",
             "height":height,
             "overflow-y":"scroll",
         })

     }
 }

 useEffect(()=>{
     determineCloseOrExpirationColumn()
 },[])

    return(<div className="table-container" style={containerHeight}>
        <table className="assignment-table">
            <thead>
            <tr className="table-name">
                <td colSpan={5}>{name}</td>
            </tr>
            <tr>
                <th>VIN</th>
                <th>Policy/Contract</th>
                <th>Product Type</th>
                <th>Request Date/Time (EST)</th>
                <th>{closeExpireColumn}</th>
            </tr>
            </thead>

            <tbody>
            {

                tabledata.map((tempdata, index) => {
                    // @ts-ignore
                    return (<tr key="{tempdata.teamworkBpiNb}" onClick={()=>handleClick(tempdata.teamworkBpiNb)}>
                                <td>{tempdata.vin}</td>
                                <td>{tempdata.insurancePolicyNumber}</td>
                                <td>{tempdata.productType}</td>
                                <td>{tempdata.requestDate}</td>
                                <td>{tempdata.otherDate}</td>
                            </tr>
                    )
                })

            }
            </tbody>
        </table>
    </div>)
}

export default AssignmentTable