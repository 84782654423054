import React from 'react'
import '../css/overlay.css'

// @ts-ignore
function Overlay({isOpen, onClose,children})
{
    return(
        <>{ 
            isOpen ?
                <div className="overlay">
                    <div className="overlay-background" onClick={onClose}/>
                    <div className="overlay-content">
                        <div className="overlay-controls">
                        </div>
                        {children}
                        <button className="overlay-close" onClick={onClose}>Close</button>
                    </div>
                </div>
                : null
        }
        </>
    )

}

export default Overlay